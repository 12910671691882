import { createContext, useReducer } from "react";

export const Store = createContext();

const initialState = {
    userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null
};


const reducer = (state, action) => {
    switch (action.type) {
        case 'ADD_NEW_FORM':
          // Add your logic to update the state based on the action here
          return {
            ...state,
            // Update relevant parts of the state based on the action
          };
        default:
          return state;
      }
}

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
  
    return (
      <Store.Provider value={{ state, dispatch }}>
        {children}
      </Store.Provider>
    );
  };