import './DetailsForm.css';
import 'react-phone-input-2/lib/style.css';
import {ReactPhoneInput} from 'react-phone-input-2';
import { Form, Col, Button } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { Store } from '../../store';
import axios from 'axios';
import { toast } from 'react-toastify';


const DetailsForm = () => {

    const { state, dispatch } = useContext(Store);

    // Form states
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [messageForm, setMessageForm] = useState('');


    //Elementar states
    const [isSubmitted, setIsSubmitted] = useState(false);

    


    const SubmitHandler = async (e) => {
        e.preventDefault();
        console.log("in submitHandler")
        try{
            const {data} = await axios.post('api/v1/letters/postLetter', {
                name,
                email,
                phone: phoneNumber,
                message: messageForm,
            });



            console.log("data recived")
            toast.success('Letter received successfully!', {
                position: toast.POSITION.BOTTOM_CENTER,
              });
        
              setIsSubmitted(true);
        }
        catch(err){
            console.log(err.message);
            toast.error('Error', {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
        console.log("finaly");
    };
    return(
        <div className="color-overlay  justify-content-center ">
            <Form className='rounded mt-5 p-4 p-sm-3'>
                <h3>רוצה לקבל עוד פרטים?</h3>
                <Form.Group className='mb-3' controlId='formBasicName'>
                    <Form.Label>שם מלא</Form.Label>
                    <Form.Control type='text' required placeholder='ישראל ישראלי' onChange={(e) => setName(e.target.value)} maxLength={38}/>
                </Form.Group>

                <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>דוא"ל</Form.Label>
                    <Form.Control type="email" required placeholder='someone@example.com' onChange={(e) => setEmail(e.target.value)} maxLength={80}/>
                    
                </Form.Group>

                <Form.Group className='mb-3' controlId='phoneNumber'>
                    <Form.Label>טלפון</Form.Label>
                    <Form.Control type='phone' placeholder='054-5554444' required onChange={(e) => setPhoneNumber(e.target.value)} maxLength={10}/>
                </Form.Group>

                <Form.Group className='client-message-input mb-3' controlId='formBasicText'>
                    <Form.Label>איך נוכל לעזור?</Form.Label>
                    <Form.Control as="textarea"
                        style={{ minHeight: '150px' }} 
                        required
                        placeholder='הודעות שמתקבלות אצלנו נמסרות אך ורק לגורמים המוסמכים לכך'
                        onChange={(e) => setMessageForm(e.target.value)}
                        maxLength={256}/>
                    <Form.Text className='text-muted'>
                        לעולם לא נשתף עם אנשים אחרים את הפרטים שלך
                    </Form.Text>
                </Form.Group>
                <Button className='submit-button' type='submit' onClick={SubmitHandler} disabled={isSubmitted}>שלח!</Button>
            </Form>
        </div>
    )
}


export default DetailsForm;