import { Route, BrowserRouter, Routes } from 'react-router-dom';

import { Container } from 'react-bootstrap';

import HomePage from './Pages/HomePage/HomePage';
import AboutPage from './Pages/AboutPage/AboutPage';

import {ToastContainer} from 'react-toastify';


import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <div className='app-content'>
        <Container>
          <Routes>
            <Route path='/' element={<HomePage />}/>
            <Route path='/about' element={<AboutPage/>}/>
          </Routes>
        </Container>
        
      </div>

    </div>
    <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
