
import { Link } from 'react-router-dom';

import { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './NavBar.css'

const NavBar = () => {

    const [isMenuOpen, setMenuOpen] = useState(false);


    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };


    return(
    <Navbar expand="md" className="navbar">
      <Navbar.Brand className='navbar-logo' href="/">
        <img
          src="/b8f2bf87056546a495bddd7f6a1848d7.png"
          alt="Logo"
          className="logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <div className='navbar-items'>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/">דף הבית</Nav.Link>
            <Nav.Link href="/About">המלצות</Nav.Link>
            <Nav.Link href="#optionC">אופציה ג</Nav.Link>
            <Nav.Link href="#optionD">אופציה ד</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
      
    </Navbar>
    )
}

export default NavBar;