
import { Container, Card} from "react-bootstrap";
import NavBar from "../../Components/NavBar/NavBar";
import './AboutPage.css';

const AboutPage = () => {
    return(
        <div>
            <Container>
                <NavBar/>
            </Container>
            <Container className="">
                <h1>המלצות</h1>
                <Card className="card mt-5">
                    <Card.Body>
                        <Card.Title>אמא מהצפון מספרת</Card.Title>
                        <Card.Text>התהליך שהילד עבר אצל ענת היה מושלם! מרגע הכניסה לקליניקה הנעימה, המפגש שלנו ההורים עם ענת שתיארה את התהליך העתידי עם הילד ואת החלק שלנו בו וכמובן החיבור הנעים בינה לבין הילד. את התוצאות התחלנו לראות כבר במפגשים הראשונים, הילד ממש חיכה למפגש ובדרכה החיננית רכשה את אמונו והצליחה ליצור שינוי חיובי ביכולת הוויסות הריגשי של הילד. הילד פרח, וכמובן שאנו ההורים היינו מרוצים עוד יותר.</Card.Text>
                    </Card.Body>
                </Card>
            </Container>
            
        </div>
    )
}
export default AboutPage;