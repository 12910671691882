import { useState, useEffect } from "react";
import NavBar from "../../Components/NavBar/NavBar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import DetailsForm from "../../Components/Form/DetailsForm";
import Map from "../../Components/Map/Map";
import "./HomePage.css";
import { Carousel } from "react-bootstrap";
import { Route } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";

const HomePage = () => {
  const [scrollToForm, setScrollToForm] = useState(false);

  useEffect(() => {
    if (scrollToForm) {
      const formElement = document.getElementById("detailsForm");

      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [scrollToForm]);

  const handleScrollToForm = () => {
    setScrollToForm(true);
  };

  return (
    <div>
      <Container className="navbar-c">
        <NavBar />
      </Container>
      <Container className="homepage-content">
        <div className="content-header mb-4 mt-1">
          <div className="image-and-buttons-container">
            <img className="anat-circle" src="/Anat-modified.png" alt="Anat" />
            <div className="buttons-container">
              
              <Col className="mt-3">
                <Button className="custom-secondary" onClick={handleScrollToForm}>
                  טופס הרשמה לפרטים נוספים
                </Button>
              </Col>
              <Col>
                <Button className="custom-dark">
                  עוד טופס להרשמה למעוניינים
                </Button>
              </Col>
            </div>
          </div>

          <Row>
              <div className="circle-buttons">
                <div className="circle-button-container-one">
                  <button className="circle-button red">
                    טיפול רגשי לילדים ומתבגרים
                  </button>
                  <img
                    className="circle-image-one"
                    src="\ClinicShot-modified.png"
                    alt="clinic"
                  />
                </div>
                <div className="circle-button-container-two">
                  <button className="circle-button green">
                    טיפול בחרדות ופוביות
                  </button>
                  <img
                    className="circle-image-one"
                    src="\Kids-modified.png"
                    alt="clinic"
                  />
                </div>
                <div className="circle-button-container-three">
                  <button className="circle-button blue">
                    טיפול בחרדות ופוביות
                  </button>
                  <img
                    className="circle-image-one"
                    src="\Kids-modified.png"
                    alt="clinic"
                  />
                </div>
                <div className="circle-button-container-four">
                  <button className="circle-button green">
                    טיפול בחרדות ופוביות
                  </button>
                  <img
                    className="circle-image-one"
                    src="\ClinicShot-modified.png"
                    alt="clinic"
                  />
                </div>
                <div className="circle-button-container-three">
                  <button className="circle-button blue">
                    טיפול בחרדות ופוביות
                  </button>
                  <img
                    className="circle-image-one"
                    src="\ClinicShot-modified.png"
                    alt="clinic"
                  />
                </div>
                {/* Add more circle-button-container elements for other buttons */}
              </div>
          </Row>
          <Row>
            <img
              className="logo-image-headline"
              src="/b8f2bf87056546a495bddd7f6a1848d7.png"
              alt="second Logo"
            />
          </Row>
        </div>
      </Container>
      <Container>
        <div className="introduction-letter mb-3">
          <h3>נעים מאוד!</h3>
          <h5>מרכז הלב המרכז לייעוץ וטיפול רגשי</h5>
          <h6>"מרכז הלב" הינו מרכז לייעוץ וטיפול רגשי הנותן מענה לילדים, מתבגרים והוריהם. </h6>
          <p>
            החזון שלנו צמח מתוך הצורך הרב שעולה מהשטח למתן תמיכה וכלים
            להתמודדות מול האתגרים עימם מתמודדים ילדים במאה ה-21 על ידי מתן
            מענה טיפולי רגשי מקצועי לילדים , מתבגרים והדרכת הורים.
          </p>
          <p>
            המרכז הוקם על ידי ענת בן ישי , יועצת חינוכית (M.A) ופסיכותרפיסטית
            קוגניטיבית התנהגותית אינטגרטיבית (CBT) מתוך רצון להעניק מגוון
            של שירותים טיפוליים המותאמים לצרכים האישיים של כל ילד/ה ונער/ה.
          </p>
        </div>
        <div className="form-map-wrapper">
          <Row >
            <Col md={6} className="form-card-wrapper" >
              <div className="form-card" id="detailsForm">
                <DetailsForm />
              </div>
            </Col>
            <Col md={4}>
              <div className="text-card mt-5">
                <h3>תהליך הפניה למרכז:</h3>
                <ol>
                  <li>ניתן לפנות למרכז באמצעות טופס פנייה בסיסי ראשוני מובנה באתר המרכז.  </li>
                  <li>לאחר שנקבל את הטופס אנו ניצור קשר לשיחת היכרות טלפונית ובירור הצרכים</li>
                  <li>חשוב להדגיש כי המטפלים העובדים במרכז הם מטפלים מומחים עצמאים כאשר כל אחד מתמחה בתחום מומחיותו וההפניה נעשית בהתאם לצרכים העולים מכם לתחום מומחיות המטפל. </li>
                </ol>
              </div>
            </Col>
            {/* <Col md={4}>
              <div className="map-card">
                <Map/>
              </div>
            </Col> */}
          </Row>
        </div>
        <div>
          {/* <Carousel/> */}
        </div>
        <div>
          <Footer/>
        </div>
      </Container>
    </div>
  );
};

export default HomePage;
